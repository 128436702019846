<template>
    <div v-loading.fullscreen.lock="loading">
        <div class="header-section">
            <div class="container">
                <div class="row login">
                    <div class="col-md-12">
                        <div class="row justify-content-center">
                            <div class="col-md-8 login-inputs mb-3">
                                <login-input></login-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Banner Section--->
        <section class="banner-section custmMob">
            <div class="container">
                <div class="banner-caption">
                    <h2>CRA Training Platform</h2>
                    <p>
                        Training solutions to become a CRA Certified Employee or comply with California’s <br />
                        Sexual Harassment Prevention Training requirements
                    </p>
                </div>
            </div>
        </section>
        <!--Banner Section--->
        <!-- Page content -->
        <div class="content-area" style="padding-top: 20px">
            <div class="container-fluid">
                <h5 class="mb-4">
                    If you are a current member of the CRA, you will receive special pricing by selecting the box next to the “Already a member of the CRA”. Enter your Member ID or name of your restaurant to receive your discount. Next, to get an estimate for one of the packages or courses, enter the required information and click the check box next to the name of the package(s) or course(s) you are interested in. Keep in mind if you select either one of the Certified Employee Programs, you will automatically be enrolled in both (which include Sexual Harassment Prevention) for no additional cost. Once your selection is complete, click the blue button that says “Click here for estimate.”

                    <br />

                    If you have any questions or don’t see a course you need, please contact us at

                    <a :href="'mailto:' + infoEmail" class="linkColor">{{ infoEmail }}</a
                    >.
                </h5>
                <div class="row">
                    <div class="col-md-7 form-section">
                        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                            <div v-if="showCompanyinformation">
                                <form role="form" @submit.prevent="handleSubmit(showAgreement)">
                                    <div class="form-row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">Company Type </label><br />
                                            <el-select class="mr-3" style="width: 100%" placeholder="Select Company Type" rules="required" v-model="company.company_type">
                                                <el-option v-for="(option, index) in company_types" class="select-primary" :value="option.value" :label="option.label" :key="'company_type_' + index"> </el-option>
                                            </el-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Company Name <span class="req"> *</span></label>
                                            <base-input type="text" name="Company name" placeholder="Company Name" rules="required" v-model="company.name"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <base-input type="text" label="Website" name="Website" placeholder="Website" v-model="company.website"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Company Logo</label>
                                            <form>
                                                <file-input v-on:change="onImageChange"></file-input>
                                            </form>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">Address <span class="req"> *</span></label>
                                            <base-input type="text" name="Address" placeholder="Address" rules="required" v-model="company.address_1"> </base-input>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">City <span class="req"> *</span></label>
                                            <base-input type="text" name="City" placeholder="city" rules="required" v-model="company.city"> </base-input>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">State <span class="req"> *</span></label>
                                            <base-input type="text" name="State" placeholder="State" rules="required" v-model="company.state"> </base-input>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">Zip code <span class="req"> *</span></label>
                                            <base-input type="number" name="Zip code" placeholder="Zip" rules="required" v-model="company.zip"> </base-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <h3 style="color: #444c57" class="mt-4 ml-2">Administrator Login Information</h3>
                                    </div>
                                    <hr />
                                    <div class="form-row">
                                        <div class="col-md-4">
                                            <label class="form-control-label">First Name <span class="req"> *</span></label>
                                            <base-input type="text" name="First Name" placeholder="First Name" rules="required" v-model="company.first_name"> </base-input>
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                            <base-input type="text" name="Last Name" placeholder="Last Name" rules="required" v-model="company.last_name"> </base-input>
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Phone <span class="req"> *</span></label>
                                            <base-input name="Phone Number" placeholder="Phone" rules="required" v-model="company.telephone_no" @input="acceptNumber"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Email Address <span class="req"> *</span></label>
                                            <base-input type="email" name="Email Address" placeholder="Email Address" rules="required" v-model="company.email"> </base-input>
                                        </div>

                                        <div class="col-md-5">
                                            <label class="form-control-label">Password <span class="req"> *</span></label>
                                            <base-input :type="passwordFieldType" v-if="!(company_id !== '')" name="Password" placeholder="Password" rules="required" v-model="company.password"> </base-input>
                                            <base-input :type="passwordFieldType" v-if="company_id !== ''" name="Password" placeholder="Password" v-model="company.password"> </base-input>
                                        </div>
                                        <div class="col-md-1 password-eye" style="margin-top: 40px">
                                            <span @click.prevent="switchVisibility"><i class="fa fa-eye" title="Show Password"></i></span>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <h3>AUTHORIZATION</h3>
                                        <p>
                                            <small> By clicking the "Create Account" button below, you agree that the name you typed in the box above (Your Full Name) will be the electronic representation of your signature for all purposes in relation to the Train 321, LLC legally binding Service Activation Agreement. You agree that your electronic signature is considered an original, for purposes of entering into a contract. </small>
                                        </p>
                                    </div>
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary mt-4">Continue</button>
                                    </div>
                                </form>
                            </div>
                            <div class="row" v-else>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">First Name <span class="req"> *</span></label>
                                            <base-input type="text" name="First Name" placeholder="First Name" rules="required" v-model="company.first_name"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                            <base-input type="text" name="Last Name" placeholder="Last Name" rules="required" v-model="company.last_name"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Phone <span class="req"> *</span></label>
                                            <base-input name="Phone Number" placeholder="Phone" rules="required" v-model="company.telephone_no" @input="acceptNumber"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Email Address <span class="req"> *</span></label>
                                            <base-input type="email" name="Email Address" placeholder="Email Address" rules="required" v-model="company.email"> </base-input>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="form-control-label">Company Name <span class="req"> *</span></label>
                                            <base-input type="text" name="Company name" placeholder="Company Name" rules="required" v-model="company.name"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label"># of Locations <span class="req"> *</span></label>
                                            <base-input type="number" min="1" name="Location" rules="required" v-model="company.no_of_locations"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Estimated # of Users <span class="req"> *</span></label>
                                            <base-input type="number" min="1" name="Users " rules="required" v-model="company.no_of_employees"> </base-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" style="font-style: italic">
                                    <h5 class="reduceFont"><span class="text-danger">*</span>Indicates a required field. The estimated pricing is based upon the number of locations, users and courses selected for your company.</h5>
                                </div>
                            </div>
                        </validation-observer>
                    </div>
                    <div class="col-md-5">
                        <div class="row">
                            <div class="col-md-12">
                                <base-checkbox v-model="already_member"><b>Already a member of the CRA? (Enter your Member ID to receive a discount).</b></base-checkbox>
                            </div>
                            <div class="col-md-11 mt-1" style="margin-left: 5%">
                                <h5>
                                    Not a member,
                                    <a class="linkColor" href="https://www.calrest.org/membership"> click here </a>
                                    to join.
                                </h5>
                            </div>

                            <div class="col-md-12 mt-1" v-if="already_member">
                                <label class="form-control-label">Member ID:</label>
                                <input type="text" :style="inputStyle" id="memberId" class="form-control" v-model="member_id" placeholder="Provide member ID" />
                            </div>
                            <div class="col-md-12 mt-2">
                                <el-checkbox-group v-model="checked_courses" >
                                    <span v-for="option in course" :key="option.id">
                                        <el-checkbox :label="option.id" v-if="option.course_type == 0" @change="courseChecked($event, option)">{{ option.course_name }}</el-checkbox
                                        ><br />
                                    </span>
                                </el-checkbox-group>

                                <!-- start here -->
                                <el-checkbox-group v-model="checked_coursefolders">
                                    <span v-for="option in coursefolders" :key="option.id">
                                        <el-popover :ref="`fromPopOver${option.id}`" placement="top-start" width="500" trigger="hover" :key="option.id" :id="option.id">
                                            <div class="provideScroller">
                                                <ul>
                                                    <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                        {{ courses.name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-popover>
                                        <el-checkbox true-label="Yes" false-label="No" @change="courseFolderChecked" :label="option.id">
                                            {{ option.coursefolder_name }}
                                        </el-checkbox>
                                        <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span><br />
                                    </span>
                                </el-checkbox-group>
                            </div>
                        </div>

                        <div class="text-right" v-if="!showCompanyinformation && (checked_courses.length || checked_coursefolders.length || checked_other_courses.length) && !lead_id">
                            <base-button class="mt-2" @click.prevent="checkIfCRAMember">Click Here for Estimate </base-button>
                        </div>
                        <div class="text-right" v-else>
                            <base-button v-if="!showPricePlan" class="basebutton mt-2" disabled>Click Here for Estimate </base-button>
                        </div>

                        <div v-if="showPricePlan && lead_id" class="price-area">
                            <hr />

                            <div class="row">
                                <div class="col-md-12" style="color: darkblue">
                                    <div class="row">
                                        <div class="col-md-9 col-8">
                                            <small style="text-decoration: underline"
                                                ><b v-if="!onlySexualCourse">Total Cost Per Month: <span class="req">*</span></b>
                                                <b v-else>Total Cost: </b>
                                            </small>
                                        </div>
                                        <div class="col-md-3 col-4">
                                            <small style="text-decoration: underline"
                                                ><b>{{ formatPrice(total_cost) }}</b></small
                                            >
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12" v-if="!onlySexualCourse" style="color: darkblue">
                                    <div class="row">
                                        <div class="col-md-9 col-8">
                                            <small
                                                >Price if paid in full for the year <span v-if="yearDiscount">({{ yearDiscount }} Off)</span>:
                                            </small>
                                            <hr style="margin-top: -9px !important; margin-bottom: 4px !important; margin-left: 250px" />
                                        </div>
                                        <div class="col-md-3 col-4">
                                            <small>{{ formatPrice(perYearCost) }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <div>
                            <base-button v-if="showCompanyinformation || lead_id" type="danger" @click.prevent="submitLead">Re-estimate </base-button>
                            <base-button style="margin-left: 20px" v-if="!showCompanyinformation && lead_id" type="success" @click.prevent="companyDetails()">Continue Signup </base-button>
                        </div>
                        <div class="mt-3">
                            <span v-if="!onlySexualCourse"
                                ><h5><span class="req">*</span>Monthly prices are based upon a 1-year agreement that will automatically renew each year.</h5></span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal :show.sync="agreementModal" class="user-modal">
            <h3 slot="header">Service Activation Agreement</h3>
            <form>
                <div class="agreement-content">
                    <agreement type="company"></agreement>
                </div>
                <div class="text-center mt-2">
                    <base-button type="success" @click.prevent="finalCreateAccount"> I Agree </base-button>
                    <base-button type="danger" @click.prevent="cancelAgreement"> Cancel </base-button>
                </div>
            </form>
        </modal>
        <modal :show.sync="showPaymentOption">
            <h4 slot="header" style="color: #444c57" class="title title-up text-center">Payment</h4>

            <credit-card v-if="showPaymentOption" type="company" :monthlyAmount="total_cost" :yearlyAmount="perYearCost" :onlySexualCourse="onlySexualCourse" :city="company.city" :state="company.state" :address="company.address_1" :zip="company.zip" :enablePaymentButton="enablePaymentButton" v-on:payClicked="payClicked" />
        </modal>
        <modal :show.sync="showCourseModal">
            <h4 slot="header" style="color: #444c57" class="title title-up text-center">Sexual Harassment Prevention</h4>
            <div class="col-md-12">
                <base-input label="How many employees will be taking this course:" type="number" name="#Employees" min="0" v-model="checkedModalCourse.numberOfUsers" />
            </div>
            <div class="col-md-12">
                <base-input label="How many managers will be taking this course:" type="number" name="#Managers" min="0" v-model="checkedModalCourse.numberOfManagers" />
            </div>
            <div class="col-md-12">
                <base-button size="md" class="custom-btn right" @click.prevent="handleAddUserCountToCourse">Done</base-button>
            </div>
        </modal>
        <modal :show.sync="paymentModalMessage">
            <h1 class="text-capitalize text-center text-strong m-0 payment-modal-text text-danger font-weight-500">Your payment is processing...</h1>
        </modal>
    </div>
</template>

<script>
import FileInput from "@/components/Inputs/FileInput";
import { Checkbox, CheckboxGroup, Option, OptionGroup, Select, Table, TableColumn } from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import CreditCard from "@/views/Widgets/CreditCard";
import Agreement from "./Agreement.vue";
import { Dynamic } from "../../wl";
import LoginInput from "@/views/Pages/LoginInput.vue";

export default {
    name: "register",
    components: {
        Agreement,
        FileInput,
        CreditCard,
        [Select.name]: Select,
        [Option.name]: Option,
        [OptionGroup.name]: OptionGroup,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        LoginInput,
    },
    data() {
        return {
            loading: false,
            paymentModalMessage: false,
            users: 0,
            already_member: false,
            member_id: "",
            courseSelectionFocused: false,
            courseFolderSelectionFocused: false,
            focused: "blue-theme",
            formData: {
                company_name: "",
                first_name: "",
                last_name: "",
                company_location_num: "",
                company_employee_num: "",
                company_address_1: "",
                company_address_2: "",
                company_phone: "",
                company_email: "",
                company_zip: "",
                website: "",
                company_type: "",
                username: "",
                parent_id: "",
                image: "",
                company_city: "",
                company_state: "",
                company_password: "",
            },
            baseUrl: this.$baseUrl,
            hot_user: "",
            hot_token: "",
            config: "",
            company_id: "",
            creatAccountClicked: false,
            company_types: [],
            image: "",
            sub_total: "",
            special_courses: [],
            company: {
                first_name: "",
                last_name: "",
                company_type: "",
                parent_company: "",
                name: "",
                administrator: "",
                no_of_locations: "",
                no_of_employees: "",
                address_1: "",
                address_2: "",
                city: "",
                state: "",
                zip: "",
                logo: "",
                telephone_no: "",
                email: "",
                password: "",
            },

            search: "",
            contactUsModal: false,
            agreementModal: false,
            passwordFieldType: "password",
            courses: [],
            coursefolders: [],
            basicPackage1: [],
            basicPackage2: [],
            services: [],
            contact: {
                name: "",
                phone: "",
                email: "",
                message: "",
            },
            siteName: "",
            infoEmail: "",
            lead_id: "",
            checked_courses: [],
            checked_other_courses: [],
            checked_coursefolders: [],
            companyEstimateDetailModel: false,
            showCompanyinformation: false,
            showPricePlan: false,
            total_cost: "",
            total_discount: "",
            perYearCost: "",
            employees_count: "",
            locations_count: "",
            course: "",
            role: "",
            showPaymentOption: false,
            onlySexualCourse: 1,
            inputStyle: "",
            enablePaymentButton: false,
            showCourseModal: false,
            checkedModalCourse: {
                courseid: 0,
                numberOfUsers: 0,
                numberOfManagers: 0,
            },
            selectedCourses: [],
            additionalCourses: [],
            swapCourses: false,
        };
    },
    mounted() {
        this.siteName = Dynamic.SITE_NAME;
        this.infoEmail = Dynamic.INFO_EMAIL;
        if (this.$route.query.inner) {
            this.$gtag.event("S.H.P. Company signup", { method: "Google" });
        } else if (this.$route.query.course_type) {
            this.swapCourses = true;
            this.$gtag.event("Sexual Harassment Prevention Company signup", {
                method: "Google",
            });
        } else {
            this.$gtag.event("Company signup", { method: "Google" });
        }
    },
    async created() {
        localStorage.removeItem("fname");
        localStorage.removeItem("lname");
        localStorage.removeItem("email");
        localStorage.removeItem("courses");
        if (this.$route.query.role) {
            this.role = this.$route.query.role;
        }

        this.$http.post("company/company_dropdown_data", {}).then((resp) => {
            for (let type of resp.data.companytype) {
                let obj = {
                    label: type.type,
                    value: type.id,
                };
                this.company_types.push(obj);
            }
        });

        let params = {
            isSexualHarassment: 1,
        };

        await this.$http.get("user/discountRules", { params }).then((resp) => {
            for (let course of resp.data.courses) {
                if (course.course_type == 0) {
                    let obj = {
                        checked: false,
                        id: course.id,
                        course_name: course.name,
                        course_type: course.course_type,
                        cost: course.cost,
                    };
                    this.courses.push(obj);
                }
            }
            this.course = this.courses;

            for (let coursefolder of resp.data.course_folders) {
                if (coursefolder.folder_type == 1) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        attachedCourses: coursefolder.active_courses,
                    };

                    this.coursefolders.push(obj1);
                }
                if (coursefolder.folder_type == 2) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        attachedCourses: coursefolder.active_courses,
                    };

                    this.basicPackage1.push(obj1);
                }
                if (coursefolder.folder_type == 3) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        attachedCourses: coursefolder.active_courses,
                    };

                    this.basicPackage2.push(obj1);
                }
            }
        });
        if (this.$route.query.course_type) {
            this.checkdefaultSexualCourse();
        }
    },
    methods: {
        courseFolderChecked(value) {
            if (value == "Yes") {
                this.checked_coursefolders = [];
                this.coursefolders.forEach((chck) => {
                    this.checked_coursefolders.push(chck.id);
                });
            } else {
                this.checked_coursefolders = [];
            }
        },
        checkdefaultSexualCourse() {
            // this.courses.forEach(chck => {
            //     if (chck.course_type == 0) {
            //         this.checked_courses.push(chck.id);
            //     }
            // });
        },
        showDone() {
            this.courseSelectionFocused = true;
        },
        doneClicked(e) {
            this.courseSelectionFocused = false;
        },
        showDoneButton() {
            this.courseFolderSelectionFocused = true;
        },
        doneButtonClicked(e) {
            this.courseFolderSelectionFocused = false;
        },
        cancelAgreement() {
            this.agreementModal = false;
        },
        showContactUs() {
            this.contactUsModal = true;
        },
        finalCreateAccount() {
            //this.createAccount(this.formData);
            this.agreementModal = false;
            this.showPaymentOption = true;
        },
        payClicked(cardData) {
            this.loading = true;
            this.paymentModalMessage = true;
            let payment = {
                payment_type: "",
                cardholder_street_address: cardData.address + "," + cardData.city + "," + cardData.state,
                cardholder_zip: cardData.zip,
                transaction_amount: "",
                token: cardData.token,
            };
            if (this.onlySexualCourse) {
                payment.payment_type = "one-time";
            } else {
                payment.payment_type = cardData.paymentType;
            }
            if (cardData.paymentType == "monthly") {
                payment.transaction_amount = this.total_cost.toFixed(2);
            }
            if (cardData.paymentType == "yearly") {
                payment.transaction_amount = this.perYearCost.toFixed(2);
            }
            this.formData.payment = payment;
            this.formData.address_1 = cardData.address;
            this.formData.company_address_1 = cardData.address;
            this.formData.company_state = cardData.state;
            this.formData.company_city = cardData.city;
            this.formData.company_zip = cardData.zip;
            this.loading = false;
            this.createAccount(this.formData);
        },
        showAgreement() {
            this.formData = {
                company_name: this.company.name,
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                company_location_num: this.company.no_of_locations,
                company_employee_num: this.company.no_of_employees,
                company_address_1: this.company.address_1,
                company_address_2: this.company.address_2,
                company_phone: this.company.telephone_no,
                company_email: this.company.email,
                company_zip: this.company.zip,
                website: this.company.website,
                company_type: this.company.company_type,
                username: this.company.email,
                parent_id: this.company.parent_company,
                image: this.image,
                company_city: this.company.city,
                company_state: this.company.state,
                company_password: this.company.password,
                course_ids: this.checked_courses,
                courseFolders: this.checked_coursefolders,
                otherCourseFolders: this.checked_other_courses,
                already_member: this.already_member,
                member_id: this.member_id,
                status: 1,
                payment: [],
                card_info: [],
                i_agree: true,
            };
            this.agreementModal = true;
        },

        formatPrice(value) {
            return "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },
        checkIfCRAMember() {
            if (!this.already_member) {
                Swal.fire({
                    title: "Already a CRA Member?",
                    html: '<a target="__blank" href="https://www.calrest.org/membership"> Click here</a> to join.',
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success btn-fill",
                    cancelButtonClass: "btn btn-danger btn-fill",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    buttonsStyling: false,
                    icon: "question",
                }).then((result) => {
                    if (result.value) {
                        this.inputStyle = "border:2px solid #f8981c;";
                        this.already_member = true;
                    } else {
                        this.submitLead();
                    }
                });
            } else {
                this.submitLead();
            }
        },
        submitLead() {
          if (!this.company.first_name || !this.company.last_name || !this.company.name || !this.company.no_of_locations || !this.company.no_of_employees) {
          return Swal.fire({
              title: "Error!",
              html: "Please fill in all required fields.",
              icon: "error",
              confirmButtonClass: "btn btn-danger btn-fill",
              confirmButtonText: "OK",
          });
            }
            if(this.company.no_of_employees == 1) {
                Swal.fire({
                    title: "Attention!",
                    html: "Looks like you are requesting the quote for individual user. We are redirecting you Individual sign up page",
                    icon: "warning",
                    confirmButtonClass: "btn btn-success btn-fill",
                    confirmButtonText: "OK",
                });
                if(this.$route.query.course_type != undefined) {
                    this.$router.push("/user_register?course_type=sexual_harrasment");
                } else {
                    this.$router.push("/user_register");
                }
            }
            if (this.company.no_of_employees !== "" && this.company.no_of_employees > 201) {
                return Swal.fire({
                    title: "Attention!",
                    html: "Please contact our sales team at <a href='mailto:cra@train321.com'>cra@train321.com</a> for special pricing.",
                    icon: "warning",
                    confirmButtonClass: "btn btn-success btn-fill",
                    confirmButtonText: "OK",
                });
            }
            this.loading = true;
            let data = {
                company_name: this.company.name,
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                number_of_locations: this.company.no_of_locations,
                number_of_employees: this.company.no_of_employees,
                email: this.company.email,
                phone_num: this.company.telephone_no,
                user_type: "corporate",
                already_member: this.already_member,
                member_id: this.member_id,
                course_ids: this.checked_courses,
                course_folders: this.checked_coursefolders,
                other_courses: this.checked_other_courses,
                checkedModalCourses: this.checkedModalCourse,
            };
            this.$http
                .post("user/lead", data)
                .then((resp) => {
                    this.lead_id = resp.data.user_id;
                    this.companyEstimateDetailModel = false;
                    this.total_cost = resp.data.sub_total;
                    this.sub_total = resp.data.sub_total;
                    this.employees_count = resp.data.number_of_employees;
                    this.locations_count = resp.data.number_of_locations;
                    this.showPricePlan = true;
                    this.perYearCost = resp.data.perYearCost;
                    this.yearDiscount = resp.data.yearDiscount;
                    this.discount = resp.data.discount;
                    this.onlySexualCourse = resp.data.only_sexual_course;
                    this.loading = false;
                    this.additionalCourses = resp.data.additionalCourses;
                })
                .catch(function (error) {
                    this.loading = false;
                    this.paymentModalMessage = false;
                    if (error.response.status === 422) {
                        return Swal.fire({
                            title: "Error!",
                            html: error.response.data.message,
                            icon: "error",
                        });
                    }
                });
        },
        companyDetails() {
            this.showCompanyinformation = true;
        },
        companyEstimateDetail() {
            this.companyEstimateDetailModel = true;
        },
        hideModel() {
            this.companyEstimateDetailModel = false;
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        cancelContact() {
            this.contactUsModal = false;
        },
        saveContact() {
            this.loading = true;
            let data = {
                name: this.contact.name,
                email: this.contact.email,
                phone: this.contact.phone,
                message: this.contact.message,
            };
            this.$http
                .post("user/contact", data)
                .then((resp) => {
                    this.contactUsModal = false;
                    this.loading = false;
                    Swal.fire({
                        title: "Success!",
                        text: resp.data.message,
                        icon: "success",
                    });
                })
                .catch(function (error) {
                    this.loading = false;
                    if (error.response.status === 422) {
                        let respmessage = error.response.data.message.replace(/,/g, "\n");
                        Swal.fire({
                            title: "Error!",
                            text: respmessage,
                            icon: "error",
                        });
                    }
                });
        },

        acceptNumber() {
            var x = this.company.telephone_no.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.company.telephone_no = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

            var y = this.contact.phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.contact.phone = !y[2] ? y[1] : "(" + y[1] + ") " + y[2] + (y[3] ? "-" + y[3] : "");
        },
        onImageChange(e) {
            let files = e;
            if (!files.length) return;
            this.createImage(files[0]);
        },
        createImage(file) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        createAccount(formDataSubmitted) {
            delete this.$http.defaults.headers["authorization"];

            formDataSubmitted.additionalCourses = this.additionalCourses;
            formDataSubmitted.checkedModalCourses = this.checkedModalCourse;

            this.creatAccountClicked = true;
            this.loading = true;
            this.$http
                .post("company/register", formDataSubmitted)
                .then((resp) => {
                    let ids = [];
                    let obj = {
                        id: resp.data.id,
                    };
                    ids.push(obj);
                    this.$http
                        .post("company/welcome_email", {
                            form_data: formDataSubmitted,
                            password: this.company.password,
                            ids: ids,
                        })
                        .then((resp) => {
                            this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                            this.paymentModalMessage = false;
                            Swal.fire({
                                title: "Success!",
                                text: `Account created successfully.`,
                                icon: "success",
                            });
                            this.$router.push("/login");
                        })
                        .catch((error) => {
                            this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                            this.paymentModalMessage = false;
                            this.$router.push("/login");
                            return Swal.fire({
                                title: "Success!",
                                text: "Account created successfully.",
                                icon: "success",
                            });
                        });
                })
                .catch((error) => {
                    this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                    this.paymentModalMessage = false;
                    return Swal.fire({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                    });
                });
        },
        courseChecked: function (isChecked, course) {
            if (course.course_name.includes("Sexual Harassment Prevention") && isChecked === true) {
                this.checkedModalCourse.courseid = course.id;
                this.showCourseModal = true;
            } else {
                this.checkedModalCourse.courseid = 0;
                this.checkedModalCourse.numberOfUsers = 0;
                this.checkedModalCourse.numberOfManagers = 0;
            }
        },
        handleAddUserCountToCourse: function () {
            if (this.checkedModalCourse.numberOfUsers == 0 && this.checkedModalCourse.numberOfManagers == 0) {
                this.checked_courses.forEach((courseId, index) => {
                    if (courseId === this.checkedModalCourse.courseid) {
                        this.checked_courses.splice(index, 1);
                    }
                });
            }
            this.showCourseModal = false;
        },
    },
};
</script>
<style scoped>
.payment-modal-text {
    animation: textBlink 2s linear infinite;
}
@keyframes textBlink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.form-section {
    background-color: transparent;
    padding: 40px;
    border-right: 1px solid #999999;
}

.course-section {
    padding: 40px;
    background-color: #ffffff !important;
}

.py-5 {
    padding-bottom: 0px !important;
}

.mt--6 {
    margin-top: -6rem !important;
}

.mt--12 {
    margin-top: -12rem !important;
}

.search-wrapper {
    position: relative;
}

.courseList {
    max-height: 250px !important;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
}

hr {
    margin-top: 2px !important;
    margin-bottom: 20px !important;
}

.basebutton.disabled:hover {
    cursor: not-allowed;
}

@media (min-width: 992px) {
    .pt-lg-9,
    .py-lg-9 {
        padding-top: 3rem !important;
    }

    .password-eye span {
        border: 1px solid #808080b3;
        padding: 8px;
        border-radius: 5px;
        background: #80808029;
    }
}

@media (min-width: 768px) {
    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 25.33333%;
    }
}

/* ============17/11/2020============ */

#selected_course li {
    font-size: 0.89em;
}

#serviceAgreement {
    float: left;
    height: 300px;
    overflow: auto;
}

#serviceAgreement p {
    font-size: 0.81rem;
    text-align: justify;
}

.el-select-group__title {
    text-decoration: underline !important;
    font-weight: bolder !important;
}

.el-select-dropdown__item {
    font-size: 13px !important;
}

.reduceFont {
    font-weight: 400 !important;
}

.price-area .row {
    margin-top: 5px;
}

.bg-gradient-primary {
    background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}

.req {
    color: red;
}

.knowMore-info {
    font-size: 13px;
    margin-left: 6px;
    text-decoration: underline;
    font-style: italic;
    color: #ef8f1d !important;
    cursor: pointer;
}

.provideScroller {
    padding-top: 10px;
    overflow-y: auto !important;
    max-height: 250px !important;
}

.login-inputs {
    padding-top: 20px;
}

.ctm-btn {
    background-color: #ef8f1d;
    color: #ffffff;
    border-radius: 0 !important;
    font-size: 17px;
    margin-right: 5px;
}

.login {
    margin-right: 0px !important;
}

@media (max-width: 767px) {
    .custmMob {
        margin-top: 200px;
    }
    .header-section {
        top: -285px;
    }
    .login {
        margin-right: -15px !important;
    }
}
</style>
